import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна  | Мотошкола ГудОк
			</title>
			<meta name={"description"} content={"Їдьте з упевненістю, вчіться з майстерністю - ваша подорож починається тут"} />
			<meta property={"og:title"} content={"Головна  | Мотошкола ГудОк"} />
			<meta property={"og:description"} content={"Їдьте з упевненістю, вчіться з майстерністю - ваша подорож починається тут"} />
			<meta property={"og:image"} content={"https://solarioxa.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://solarioxa.com/img/751463.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://solarioxa.com/img/751463.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://solarioxa.com/img/751463.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://solarioxa.com/img/751463.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://solarioxa.com/img/751463.png"} />
			<meta name={"msapplication-TileImage"} content={"https://solarioxa.com/img/751463.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="180px 0 180px 0" background="linear-gradient(0deg,rgba(0, 0, 0, 0.4) 0%,rgba(0,0,0,0.4) 100%),rgba(0, 0, 0, 0) url(https://solarioxa.com/img/1.jpg) 50% 50% /cover repeat scroll padding-box" md-padding="100px 0 100px 0" quarkly-title="Content-12">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				justify-content="flex-start"
				md-flex-direction="column"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 80px 0px 0px"
				width="50%"
				lg-width="70%"
				md-width="100%"
				md-padding="0px 0 0px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Text margin="0px 0px 10px 0px" font="normal 500 20px/1.5 --fontFamily-sans" color="white">
					Мотошкола
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline2" color="white">
					 ГудОк
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
				Ласкаво просимо до мотошколи ГудОк, найкращого тренувального майданчика як для початківців, так і для досвідчених мотоциклістів. Наша школа присвячена навчанню мистецтву та науці їзди на мотоциклі, щоб гарантувати, що кожен учень покине наш майданчик не просто гонщиком, а по-справжньому досвідченим мотоциклістом.
				</Text>
				<Button
					href="/contacts"
					type="link"
					text-decoration-line="initial"
					font="normal 400 16px/1.5 --fontFamily-sans"
					background="--color-dark"
					padding="12px 28px 12px 28px"
				>
					Запланувати перший урок
				</Button>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
					Комплексні навчальні програми
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Від курсів для початківців до просунутих технік їзди, наші програми охоплюють всі аспекти їзди на мотоциклі.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
					Досвідчені інструктори
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Вчіться у пристрасних, кваліфікованих інструкторів, які мають багаторічний досвід катання та викладання, щоб забезпечити найвищу якість навчання.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
					Найсучасніше обладнання
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Тренуйтеся на різноманітних мотоциклах, кожен з яких оснащений найсучаснішими функціями для забезпечення повного досвіду навчання.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Унікальні переваги
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Навчальні сесії, що налаштовуються: Налаштуйте свій навчальний досвід за допомогою індивідуальних занять, які відповідають вашим конкретним потребам і цілям.
					<br/><br/>
Реальні сценарії водіння: Практикуйтеся в різноманітних реальних сценаріях, щоб розвинути навички, які виходять за рамки базових і підготувати вас до реального дорожнього досвіду.

				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://solarioxa.com/img/2.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});